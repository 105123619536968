





















































import { Component, Vue } from "vue-property-decorator";
import noticeModule from "../store/NoticeModule";
import MessageDialog from "./MessageDialog.vue";
import NtcEdtConfirmationDialog from "./NtcEdtConfirmationDialog.vue";
import RequestErrorDialog from "./RequestErrorDialog.vue";

@Component({
  components: {
    "message-dialog": MessageDialog,
    "confirmation-dialog": NtcEdtConfirmationDialog,
    "request-error-dialog": RequestErrorDialog
  }
})
export default class NoticeEditor extends Vue {
  /** 配信お知らせ情報の入力値 */
  noticeInput = "";

  /** 画面遷移時にデータ読み込みのエラー判定 */
  mountedOk = false;

  /** ダイアログの表示フラグ */
  dialog = {
    message: false,
    confirm: false,
    requestError: false
  };

  /** httpステータスコード */
  httpStatus = 500;

  /** メッセージダイアログのテキスト（固定値） */
  messageDialogText = {
    title: "登録完了",
    message: "登録が完了しました。"
  };

  /** エラーダイアログのテキスト */
  requestErrorDialogMessage = "";

  /**
   * DBの配信お知らせ情報を編集する。
   */
  async editNotice(): Promise<void> {
    const notice = {
      notice: this.noticeInput
    };
    let result = false;
    try {
      this.dialog.confirm = false;
      result = await noticeModule.editNotice(notice);
    } catch (error) {
      this.requestErrorDialogMessage = "登録に失敗しました。";
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
    if (result === true) {
      await this.loadDataToStore();
      this.copyStoreData();
      this.dialog.message = true;
    }
  }

  /**
   * ひとつ前のページに遷移する。
   */
  goToPreviousPage(): void {
    this.$router.back();
  }

  /**
   * Storeのデータをコピーする。
   */
  copyStoreData(): void {
    // Storeのデータを書き換えないように、一度Json型に変換。
    this.noticeInput = JSON.parse(JSON.stringify(noticeModule.notice)).notice;
  }

  /**
   * データをDBからStoreに取り込む
   */
  async loadDataToStore(): Promise<void> {
    let result = false;
    try {
      result = await noticeModule.getNotice();
    } catch (error) {
      this.requestErrorDialogMessage = "";
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
    if (result === true) {
      this.httpStatus = 200;
      this.mountedOk = true;
    }
  }

  /**
   * 配信お知らせ情報入力画面に遷移した時に実行
   */
  async mounted() {
    await this.loadDataToStore();
    if (this.mountedOk === true) {
      this.copyStoreData();
    }
  }
}
