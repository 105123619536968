import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";

export interface DeliveryTypeResponse extends ApiResponse {
  result: {
    deliveryTypeList: DeliveryType[];
  };
}

export type DeliveryType = {
  code: number;
  name: string;
};

class DeliveryTypeApi {
  API_SERVER = config.api.access_url;

  /**
   * 配信区分リスト取得
   * @return {Promise<string, DeliveryTypeResponse>} httpステータス・APIレスポンス・配信区分リスト
   */
  public async getDeliveryTypeList(): Promise<{
    httpStatus: number;
    deliveryTypeResponse: DeliveryTypeResponse;
  }> {
    const url = this.API_SERVER + "/api/delivery-types/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<DeliveryTypeResponse>(url, config);
    return {
      httpStatus: response.status,
      deliveryTypeResponse: response.data
    };
  }
}

export const deliveryTypeApi = new DeliveryTypeApi();
