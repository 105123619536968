


















import { Component, Vue, PropSync, Emit } from "vue-property-decorator";

@Component
export default class DeleteConfirmationDialog extends Vue {
  /** ダイアログ表示フラグ */
  @PropSync("confirmationDialogFlag", { type: Boolean, default: false })
  deleteDialog!: boolean;

  /**
   * 削除実行時の処理
   * ※処理は親コンポーネントの関数で行う。
   */
  @Emit("deleteData")
  deleteData(): void {
    this.deleteDialog = false;
    return;
  }
}
