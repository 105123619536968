



















































































































































import { Component, Vue } from "vue-property-decorator";
import errorModule, { ErrorDataList } from "../store/ErrorModule";
import useTypeModule from "../store/UseTypeModule";
import errorStatusModule from "../store/ErrorStatusModule";
import RequestErrorDialog from "./RequestErrorDialog.vue";

export type ErrorData = {
  errorId: number;
  detectDateTime: string;
  detectKeyword: string;
  serverName: string;
  statusCode: number;
  statusName: string;
  memo: string;
  useTypeCode: number;
  logFile: string;
};
export type UseTypeListForSelect = {
  code: number | null;
  name: string;
}[];
export type ErrorStatusListForSelect = {
  code: number | null;
  name: string;
}[];
@Component({
  components: {
    "request-error-dialog": RequestErrorDialog
  }
})
export default class ErrorList extends Vue {
  /** ダイアログ表示フラグ */
  dialog = {
    requestError: false
  };

  /** httpステータスコード */
  httpStatus = 500;

  /** 画面遷移時にデータ読み込みのエラー判定 */
  mountedOk = false;

  /** リクエストエラーダイアログのテキスト変数 */
  requestErrorDialogMessage = "";

  /** カレンダー用フラグ */
  calendar = {
    start: false,
    end: false
  };

  /** フィルター条件用の変数 */
  filter: {
    date: {
      start: string | null;
      end: string | null;
    };
    useTypeCode: number | null;
    statusCode: number | null;
  } = {
    date: {
      start: "",
      end: ""
    },
    useTypeCode: null,
    statusCode: null
  };

  /** v-data-table用のヘッダープロパティ */
  tableHeaders = [
    {
      text: "ログ出力日時",
      value: "detectDateTime",
      width: "18%",
      filter: this.filterDate
    },
    { text: "検知文字", value: "detectKeyword", width: "14%" },
    { text: "サーバ名", value: "serverName", width: "14%" },
    {
      text: "用途種別",
      value: "useTypeCode",
      align: " d-none",
      filter: this.filterUseType
    },
    { text: "ログファイル名", value: "logFile", width: "23%" },
    { text: "ステータス", value: "statusName", width: "16%", align: "center" },
    {
      text: "ステータスコード",
      value: "statusCode",
      align: " d-none",
      filter: this.filterStatus
    },
    { text: "備考", value: "memo" }
  ];

  /** 絞り込み条件用の、対象サーバとステータスの全選択の選択肢 */
  allSelectOption = {
    code: null,
    name: "全て"
  };

  /**
   * 日付データのフォーマットをYYYY/MM/DD HH:MM.SS型に変換する。
   * @param {string} 変換前の日付データ
   * @returns {string} 変換後の日付データ
   */
  changeFormatOfDate(dateTimeStr: string): string {
    if (dateTimeStr === "") {
      return "";
    }
    const dateTime = new Date(dateTimeStr);
    const year = ("0000" + dateTime.getFullYear()).slice(-4);
    const month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    const date = ("0" + dateTime.getDate()).slice(-2);
    const hour = ("0" + dateTime.getHours()).slice(-2);
    const minute = ("0" + dateTime.getMinutes()).slice(-2);
    const second = ("0" + dateTime.getSeconds()).slice(-2);

    return `${year}/${month}/${date} ` + `${hour}:${minute}:${second}`;
  }

  /**
   * フィルター条件をクリアし、データをリロードする。
   */
  clearFilterAndLoadData(): void {
    this.filter = {
      date: {
        start: "",
        end: ""
      },
      useTypeCode: null,
      statusCode: null
    };

    this.loadAllDataToStore();
  }

  /**
   * メニューページに遷移する。
   */
  backToMenu(): void {
    this.$router.back();
  }

  /**
   * 障害設定画面に遷移する。
   */
  goToErrorSetting() {
    this.$router.push({ name: "ErrorSetting" });
  }

  /**
   * 障害設定画面に遷移する。
   */
  goToErrorDetail(errorData: ErrorData): void {
    this.$router.push({
      name: "ErrorDetail",
      params: { id: errorData.errorId.toString() }
    });
  }

  /**
   * Storeから障害データリストを取得
   * @return {ErrorList} 障害データリスト
   */
  get errorList(): ErrorDataList {
    if (this.httpStatus !== 200 && this.mountedOk !== true) {
      return [];
    }
    return errorModule.errorList;
  }

  /**
   * Storeから用途種別リストを取得
   * @return {UseTypeListForSelect} 用途種別リスト
   */
  get useTypeList(): UseTypeListForSelect {
    // Store内のStateの値を連動して書き換えないように、一度string型に変換し、再度Json型に変換する。
    const useTypeList = JSON.parse(JSON.stringify(useTypeModule.useTypeList));
    useTypeList.splice(0, 0, this.allSelectOption);
    return useTypeList;
  }

  /**
   * Storeから障害ステータスリストを取得
   * @return {ErrorStatusListForSelect} 障害ステータスリスト
   */
  get errorStatusList(): ErrorStatusListForSelect {
    // Store内のStateの値を連動して書き換えないように、一度string型に変換し、再度Json型に変換する。
    const errorStatusList = JSON.parse(
      JSON.stringify(errorStatusModule.errorStatusList)
    );
    errorStatusList.splice(0, 0, this.allSelectOption);
    return errorStatusList;
  }

  /**
   * フィルター条件で指定された日付の値をDate型に変換して返す。
   * @returns {Date} フィルター条件の開始日時
   */
  get dateStart(): Date {
    if (this.filter.date.start !== null && this.filter.date.start !== "") {
      return new Date(
        new Date(this.filter.date.start).getFullYear(),
        new Date(this.filter.date.start).getMonth(),
        new Date(this.filter.date.start).getDate()
      );
    } else {
      return new Date();
    }
  }

  /**
   * フィルター条件で指定された終了の日にちに+1日した値をDate型に変換して返す。
   * @returns {Date} フィルター条件の終了日時 + 1日
   */
  get dateEnd(): Date {
    if (this.filter.date.end !== null && this.filter.date.end !== "") {
      return new Date(
        new Date(
          new Date(this.filter.date.end).setDate(
            new Date(this.filter.date.end).getDate() + 1
          )
        ).getFullYear(),
        new Date(
          new Date(this.filter.date.end).setDate(
            new Date(this.filter.date.end).getDate() + 1
          )
        ).getMonth(),
        new Date(
          new Date(this.filter.date.end).setDate(
            new Date(this.filter.date.end).getDate() + 1
          )
        ).getDate()
      );
    } else {
      return new Date();
    }
  }

  /**
   * 日付のフィルター機能
   * @param {string} value - ログ出力日時
   * @return {boolean} フィルタリング結果
   */
  filterDate(value: string): boolean {
    const valueDateTime = new Date(value);
    if (this.filter.date.start !== null && this.filter.date.start !== "") {
      if (this.filter.date.end !== null && this.filter.date.end !== "") {
        if (this.dateStart <= valueDateTime && valueDateTime < this.dateEnd) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.dateStart <= valueDateTime) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (this.filter.date.end !== null && this.filter.date.end !== "") {
        if (valueDateTime < this.dateEnd) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }

  /**
   * 対象サーバのフィルター機能
   * @param {number} value - エラーのサーバ種別コード
   * @return {boolean} フィルタリング結果
   */
  filterUseType(value: number): boolean {
    if (this.filter.useTypeCode !== null) {
      if (this.filter.useTypeCode === value) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * ステータスのフィルター機能
   * @param {number} value - エラーのステータス種別コード
   * @return {boolean} フィルタリング結果
   */
  filterStatus(value: number): boolean {
    if (this.filter.statusCode !== null) {
      if (this.filter.statusCode === value) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * 各データをDBからStoreに取り込む
   */
  async loadAllDataToStore(): Promise<void> {
    let result = {
      error: false,
      useType: false,
      status: false
    };
    try {
      result = {
        error: await errorModule.getErrorList(),
        useType: await useTypeModule.getUseTypeList(),
        status: await errorStatusModule.getErrorStatusList()
      };
    } catch (error) {
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
    if (
      result.error === true &&
      result.useType === true &&
      result.status === true
    ) {
      this.httpStatus = 200;
    }
  }

  /**
   * 障害一覧画面へ遷移した時に実行
   */
  async mounted() {
    await this.loadAllDataToStore();
    if (this.httpStatus === 200) {
      this.mountedOk = true;
    }
  }
}
