import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { serverDataApi, ServerDataListResponse } from "@/api/ServerDataApi";
import { NETWORK_ERROR } from "@/router";

export interface ServerModuleBase {
  /**
   * サーバデータリスト
   */
  serverDataList: ServerDataList;
}
export type ServerDataList = {
  serverId: string;
  serverName: string;
  ipAddress: string;
  useType: {
    useTypeCode: number;
    useTypeName: string;
  };
}[];
@Module({ dynamic: true, store: store, name: "ServerModule", namespaced: true })
class ServerModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements ServerModuleBase {
  /**
   * サーバデータリスト
   */
  public serverDataList = [
    {
      serverId: "",
      serverName: "",
      ipAddress: "",
      useType: {
        useTypeCode: 0,
        useTypeName: ""
      }
    }
  ];

  /**
   * サーバデータリストをStoreに格納
   * @param {ServerDataList} serverDataList - サーバデータリスト
   */
  @Mutation
  public commitServerDataList(serverDataList: ServerDataList): void {
    this.serverDataList = serverDataList;
  }

  /**
   * サーバデータリストの取得
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getServerDataList(): Promise<boolean> {
    let httpStatus: number;
    let serverDataListResponse: ServerDataListResponse;
    let serverDataList: ServerDataList;
    try {
      const result = await serverDataApi.getServerDataList();
      httpStatus = result.httpStatus;
      serverDataListResponse = result.serverDataListResponse;
      serverDataList = serverDataListResponse.result.serverDataList;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitServerDataList(serverDataList);
      return true;
    } else {
      return false;
    }
  }
}

const serverModule: ServerModule = getModule(ServerModule);
export default serverModule;
