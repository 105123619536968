import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

import Login from "@/views/auth/Login.vue";
import Menu from "@/views/Menu.vue";
import ContractList from "@/views/ContractList.vue";
import ContractDetail from "@/views/ContractDetail.vue";
import ServerList from "@/views/ServerList.vue";
import ErrorList from "@/views/ErrorList.vue";
import ErrorSetting from "@/views/ErrorSetting.vue";
import ErrorDetail from "@/views/ErrorDetail.vue";
import NoticeEditor from "@/views/NoticeEditor.vue";

import BadRequest from "@/views/error/BadRequest.vue";
import Deny from "@/views/error/Deny.vue";
import NotFound from "@/views/error/NotFound.vue";
import InternalServerError from "@/views/error/InternalServerError.vue";
import NetworkError from "@/views/error/NetworkError.vue";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { SESSION_STORAGE_KEY_TOKEN } from "@/store/LoginModule";

Vue.use(VueRouter);

export const NETWORK_ERROR = "NetworkError";

export const publicRoute: RouteConfig[] = [
  { path: "*", component: NotFound },

  {
    path: "/auth",
    component: AuthLayout,
    meta: { title: "Login" },
    redirect: "/auth/login",
    children: [
      {
        path: "login",
        name: "Login",
        meta: { title: "ログイン" },
        component: Login
      }
    ]
  },

  {
    path: "/400",
    name: "400",
    meta: { title: "Bad Request" },
    component: BadRequest
  },

  {
    path: "/404",
    name: "404",
    meta: { title: "Not Found" },
    component: NotFound
  },

  {
    path: "/500",
    name: "500",
    meta: { title: "Internal Server Error" },
    component: InternalServerError
  },

  {
    path: "/NetworkError",
    name: NETWORK_ERROR,
    meta: { title: "Network Error" },
    component: NetworkError
  }
];

const protectedRoute: RouteConfig[] = [
  {
    path: "/",
    component: DefaultLayout,
    meta: { title: "Home", group: "apps", icon: "" },
    redirect: "/menu",
    children: [
      {
        path: "menu",
        name: "Menu",
        meta: { title: "Menu", group: "apps", icon: "menu" },
        component: Menu
      },

      {
        path: "403",
        name: "Forbidden",
        meta: { title: "Access Denied", hiddenInMenu: true },
        component: Deny
      }
    ]
  },

  {
    path: "/contract",
    component: DefaultLayout,
    redirect: "/contract/list",
    meta: { title: "Contract", group: "contract", icon: "" },
    children: [
      {
        path: "list",
        name: "ContractList",
        meta: { title: "契約者一覧" },
        component: ContractList
      },
      {
        path: "detail/:userId?",
        name: "ContractDetail",
        meta: { title: "契約者詳細" },
        component: ContractDetail
      }
    ]
  },

  {
    path: "/server",
    component: DefaultLayout,
    redirect: "/server/list",
    meta: { title: "Server", group: "server", icon: "" },
    children: [
      {
        path: "list",
        name: "ServerList",
        meta: { title: "サーバ一覧" },
        component: ServerList
      }
    ]
  },

  {
    path: "/error",
    component: DefaultLayout,
    redirect: "/error/list",
    meta: { title: "Error", group: "error", icon: "" },
    children: [
      {
        path: "list",
        name: "ErrorList",
        meta: { title: "障害一覧" },
        component: ErrorList
      },
      {
        path: "setting",
        name: "ErrorSetting",
        meta: { title: "障害設定" },
        component: ErrorSetting
      },
      {
        path: "detail/:id",
        name: "ErrorDetail",
        meta: { title: "障害詳細" },
        component: ErrorDetail
      }
    ]
  },

  {
    path: "/notice",
    component: DefaultLayout,
    redirect: "/notice/editor",
    meta: { title: "Notice", group: "notice", icon: "" },
    children: [
      {
        path: "editor",
        name: "NoticeEditor",
        meta: { title: "配信お知らせ情報入力" },
        component: NoticeEditor
      }
    ]
  }
];

const routes: RouteConfig[] = publicRoute.concat(protectedRoute);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // ログイン済みかどうか
  // ここでは LoginModule がうまく動作しないので
  // LoginModule内で使用している SessionStorage を直接確認する。
  const token = sessionStorage.getItem(SESSION_STORAGE_KEY_TOKEN);
  const isLoggedIn = token !== null && token !== "";

  // ログイン不要のページかどうか
  const isPublic =
    publicRoute.filter(routeConfig => {
      if (routeConfig.children === undefined) {
        return routeConfig.name === to.name;
      } else {
        return (
          routeConfig.children.filter(routeConfig => {
            return routeConfig.name === to.name;
          }).length > 0
        );
      }
    }).length > 0;

  if (isLoggedIn || isPublic) {
    // ログイン済み または ログイン不要のページ の場合
    next();
  } else {
    // ログインしてない場合
    NProgress.done();
    next({ name: "Login" });
  }
});

router.afterEach((_to: Route, _from: Route) => {
  NProgress.done();
});

export default router;
