




























import { Component, Vue, PropSync, Prop, Emit } from "vue-property-decorator";

@Component
export default class NtcEdtConfirmationDialog extends Vue {
  /** ダイアログ表示フラグ */
  @PropSync("confirmationDialogFlag", { type: Boolean, default: false })
  confirmationDialog!: boolean;

  /** 配信お知らせ情報の編集内容 */
  @Prop({ type: String })
  noticeInput!: string;

  /**
   * 登録実行時の処理
   * ※処理は親コンポーネントの関数で行う。
   */
  @Emit("editNotice")
  editNotice(): void {
    return;
  }
}
