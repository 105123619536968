

















































































import { Component, Vue } from "vue-property-decorator";
import errorModule, { ErrorData } from "../store/ErrorModule";
import errorStatusModule, { ErrorStatusList } from "../store/ErrorStatusModule";
import MessageDialog from "./MessageDialog.vue";
import RequestErrorDialog from "./RequestErrorDialog.vue";

@Component({
  components: {
    "message-dialog": MessageDialog,
    "request-error-dialog": RequestErrorDialog
  }
})
export default class ErrorDetail extends Vue {
  /** 画面遷移時にデータ読み込みのエラー判定 */
  mountedOk = false;

  /** ダイアログの表示フラグ */
  dialog = {
    message: false,
    requestError: false
  };

  /** httpステータスコード */
  httpStatus = 500;

  /** リクエストエラーダイアログのテキスト */
  requestErrorDialogMessage = "登録に失敗しました。";

  /** メッセージダイアログのテキスト */
  messageDialogText = {
    title: "登録完了",
    message: "登録が完了しました。"
  };

  /** 障害データの編集入力値 */
  errorDataInput: ErrorData = {
    errorId: -1,
    errorDetail: "",
    statusCode: -1,
    memo: ""
  };

  /**
   * 障害ステータス種別リスト
   * @returns {ErrorStatusList} 障害ステータス種別リスト
   */
  get errorStatusList(): ErrorStatusList {
    return errorStatusModule.errorStatusList;
  }

  /**
   * ひとつ前のページに遷移する。
   */
  goToPreviousPage(): void {
    this.$router.back();
  }

  /**
   * DBの障害データの情報を編集する。
   */
  async editErrorData(): Promise<void> {
    const errorDataInput = {
      errorId: this.errorDataInput.errorId,
      statusCode: this.errorDataInput.statusCode,
      memo: this.errorDataInput.memo
    };
    let result = false;
    try {
      result = await errorModule.editErrorData(errorDataInput);
    } catch (error) {
      this.requestErrorDialogMessage = "登録に失敗しました。";
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (error.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
    if (result === true) {
      await this.loadAllDataToStore();
      this.copyStoreData();
      this.dialog.message = true;
    }
  }

  /**
   * Storeのデータをコピーする。
   */
  copyStoreData(): void {
    // Storeのデータを書き換えないように、一度Json型に変換。
    this.errorDataInput = JSON.parse(JSON.stringify(errorModule.errorData));
  }

  /**
   * 各データをDBからStoreに取り込む
   */
  async loadAllDataToStore(): Promise<void> {
    try {
      await errorModule.getErrorData(parseInt(this.$route.params.id));
      await errorStatusModule.getErrorStatusList();
      this.mountedOk = true;
    } catch (error) {
      this.requestErrorDialogMessage = "";
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (error.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
  }

  /**
   * 障害詳細画面に遷移した時に実行
   */
  async mounted() {
    await this.loadAllDataToStore();
    if (this.mountedOk === true) {
      this.copyStoreData();
    }
  }
}
