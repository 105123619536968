import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";
import { ErrorStatusList } from "@/store/ErrorStatusModule";

export interface StatusResponse extends ApiResponse {
  result: {
    errorStatusList: ErrorStatusList;
  };
}

class StatusApi {
  API_SERVER = config.api.access_url;

  /**
   * 障害ステータス種別リスト取得
   * @return {Promise<string, StatusResponse>} httpステータス・APIレスポンス・障害ステータス種別リスト
   */
  public async getErrorStatusList(): Promise<{
    httpStatus: number;
    statusResponse: StatusResponse;
  }> {
    const url = this.API_SERVER + "/api/error-statuses/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<StatusResponse>(url, config);
    return {
      httpStatus: response.status,
      statusResponse: response.data
    };
  }
}

export const statusApi = new StatusApi();
