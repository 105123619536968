import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { statusApi, StatusResponse } from "@/api/ErrorStatusApi";
import { NETWORK_ERROR } from "@/router";

export interface ErrorStatusModuleBase {
  /**
   * 障害ステータス種別リスト
   */
  errorStatusList: ErrorStatusList;
}
export type ErrorStatusList = {
  code: number;
  name: string;
}[];
@Module({
  dynamic: true,
  store: store,
  name: "ErrorStatusModule",
  namespaced: true
})
class ErrorStatusModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements ErrorStatusModuleBase {
  /**
   * 障害ステータス種別リスト
   */
  public errorStatusList = [
    {
      code: 0,
      name: ""
    }
  ];

  /**
   * 障害ステータス種別リストをStoreに格納
   * @param {ErrorStatusList} errorStatusList - 障害ステータス種別リスト
   */
  @Mutation
  public commitStatusList(errorStatusList: ErrorStatusList): void {
    this.errorStatusList = errorStatusList;
  }

  /**
   * 障害ステータス種別リストの取得
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getErrorStatusList(): Promise<boolean> {
    let httpStatus: number;
    let statusResponse: StatusResponse;
    let statusList: ErrorStatusList;
    try {
      const result = await statusApi.getErrorStatusList();
      httpStatus = result.httpStatus;
      statusResponse = result.statusResponse;
      statusList = statusResponse.result.errorStatusList;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitStatusList(statusList);
      return true;
    } else {
      return false;
    }
  }
}

const errorStatusModule: ErrorStatusModule = getModule(ErrorStatusModule);
export default errorStatusModule;
