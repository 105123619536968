







































import { Component, Vue } from "vue-property-decorator";
import serverModule, { ServerDataList } from "../store/ServerModule";
import RequestErrorDialog from "./RequestErrorDialog.vue";

@Component({
  components: {
    "request-error-dialog": RequestErrorDialog
  }
})
export default class ServerList extends Vue {
  /** 各ダイアログのフラグ値 */
  dialog = {
    requestError: false
  };

  /** httpステータスコード */
  httpStatus = 200;

  /** 画面遷移時にデータ読み込みのエラー判定 */
  mountedOk = false;

  /** リクエストエラーダイアログのテキスト変数 */
  requestErrorDialogMessage = "";

  /** v-data-table用のヘッダープロパティ */
  tableHeaders = [
    { text: "サーバ名", value: "serverName", width: "30%" },
    { text: "サーバID", value: "serverId", width: "15%" },
    { text: "IPアドレス", value: "ipAddress", width: "30%" },
    { text: "サーバ種別", value: "useType.useTypeName" }
  ];

  /**
   * Storeからサーバデータリストを取得
   * @return {ServerDataList} サーバデータリスト
   */
  get serverDataList(): ServerDataList {
    if (this.httpStatus !== 200 && this.mountedOk !== true) {
      return [];
    }
    return serverModule.serverDataList;
  }

  /**
   * メニューページに遷移する。
   */
  backToMenu(): void {
    this.$router.back();
  }

  /**
   * データをDBからStoreに取り込む
   */
  async loadDataToStore(): Promise<void> {
    try {
      await serverModule.getServerDataList();
    } catch (error) {
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
  }

  /**
   * サーバ一覧画面へ遷移した時に実行
   */
  async mounted() {
    await this.loadDataToStore();
    if (this.httpStatus === 200) {
      this.mountedOk = true;
    }
  }
}
