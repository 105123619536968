import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { loginApi, LoginResponse } from "@/api/LoginApi";
import { NETWORK_ERROR } from "@/router";
import { Module as Mod } from "vuex";

const SESSION_STORAGE_KEY = "WodWeb.LoginModule";
export const SESSION_STORAGE_KEY_TOKEN = SESSION_STORAGE_KEY + ".token"; // router.ts で参照
const SESSION_STORAGE_KEY_USER_ID = SESSION_STORAGE_KEY + ".userId";
const SESSION_STORAGE_KEY_USER_NAME = SESSION_STORAGE_KEY + ".userName";

export interface LoginModuleBase {
  token: string;
  userId: number;
  userName: string;
}

/**
 * ログイン情報
 *
 * vue-router では LoginModule の値が見れないため SessionStorage へ保存して、それを参照する。
 */
@Module({ dynamic: true, store: store, name: "LoginModule", namespaced: true })
class LoginModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements LoginModuleBase {
  /** Constructor */
  constructor(module: Mod<S, any>) {
    super(module);

    // LoginModule インスタンスが生成されるタイミングに合わせて sessionStorage も初期化
    const token = sessionStorage.getItem(SESSION_STORAGE_KEY_TOKEN);
    if (token !== null) {
      this.token = token;
      const userIdStr = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_ID);
      if (userIdStr !== null) {
        this.userId = parseInt(userIdStr);
      }
      const userName = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_NAME);
      if (userName !== null) {
        this.userName = userName;
      }
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEY_TOKEN, "");
      sessionStorage.setItem(SESSION_STORAGE_KEY_USER_ID, "-1");
      sessionStorage.setItem(SESSION_STORAGE_KEY_USER_NAME, "");
    }
  }

  /** 認証トークン */
  public token = "";

  /** ログインユーザのID */
  public userId = -1;

  /** ログインユーザの名前 */
  public userName = "";

  @Mutation
  public updateToken(token: string): void {
    this.token = token;
    sessionStorage.setItem(SESSION_STORAGE_KEY_TOKEN, token);
  }

  @Mutation
  private updateUserId(userId: number): void {
    this.userId = userId;
    sessionStorage.setItem(SESSION_STORAGE_KEY_USER_ID, userId.toString());
  }

  @Mutation
  private updateUserName(userName: string): void {
    this.userName = userName;
    sessionStorage.setItem(SESSION_STORAGE_KEY_USER_NAME, userName);
  }

  /**
   * ログイン情報設定
   */
  @Action
  private async loggedIn(value: {
    token: string;
    userId: number;
    userName: string;
  }): Promise<void> {
    this.updateToken(value.token);
    this.updateUserId(value.userId);
    this.updateUserName(value.userName);
  }

  /**
   * ログイン情報リセット
   */
  @Action
  public async reset(): Promise<void> {
    this.updateToken("");
    this.updateUserId(-1);
    this.updateUserName("");
  }

  /**
   * ログイン認証
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async login(value: {
    loginId: string;
    password: string;
  }): Promise<boolean> {
    let httpStatus: number;
    let loginResponse: LoginResponse;
    try {
      const result = await loginApi.login(value.loginId, value.password);
      httpStatus = result.httpStatus;
      loginResponse = result.loginResponse;
    } catch (error) {
      await this.reset();
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      await this.loggedIn({
        token: loginResponse.result.token,
        userId: loginResponse.result.id,
        userName: loginResponse.result.name
      });
      return true;
    } else {
      await this.reset();
      return false;
    }
  }
}

const loginModule: LoginModule = getModule(LoginModule);
export default loginModule;
