





























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Menu extends Vue {
  clickMenu(name: string) {
    this.$router.push({ name: name });
  }
}
