import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";
import { UserInput } from "@/interfaces/UserInput";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";

export interface UserListResponse extends ApiResponse {
  result: User[];
}

export interface UserResponse extends ApiResponse {
  result: User;
}

export interface PrefListResponse extends ApiResponse {
  result: Pref[];
}

export interface User {
  userId: string;
  userName: string;
  passEditFlg: boolean;
  password: string;
  note: string;
  contracts: Contract[];
}

export interface Contract {
  delivCd: number;
  dataType: number;
  prefCodeList: number[];
}

export interface Pref {
  prefCd: number;
  prefName: string;
}

class UserApi {
  API_SERVER = config.api.access_url;

  /**
   * ユーザ一覧情報 取得
   */
  public async getUserList(): Promise<{
    httpStatus: number;
    userListResponse: UserListResponse;
  }> {
    const url = this.API_SERVER + "/api/users";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<UserListResponse>(url, config);
    return { httpStatus: response.status, userListResponse: response.data };
  }

  /**
   * ユーザ情報 取得
   */
  public async getUser(
    userId: string
  ): Promise<{
    httpStatus: number;
    userResponse: UserResponse;
  }> {
    const url = this.API_SERVER + "/api/users/" + userId;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<UserResponse>(url, config);
    return { httpStatus: response.status, userResponse: response.data };
  }

  /**
   * ユーザ情報 新規作成
   */
  public async createUser(
    value: UserInput
  ): Promise<{
    httpStatus: number;
    userResponse: UserResponse;
  }> {
    const url = this.API_SERVER + "/api/users";
    const data = JSON.stringify(value);
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.post<UserResponse>(url, data, config);
    return { httpStatus: response.status, userResponse: response.data };
  }

  /**
   * ユーザ情報 編集
   */
  public async editUser(
    userId: string,
    value: UserInput
  ): Promise<{
    httpStatus: number;
    userResponse: UserResponse;
  }> {
    const url = this.API_SERVER + "/api/users/" + userId;
    const data = JSON.stringify(value);
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.put<UserResponse>(url, data, config);
    return { httpStatus: response.status, userResponse: response.data };
  }

  /**
   * ユーザ情報 削除
   */
  public async deleteUser(
    userId: string
  ): Promise<{
    httpStatus: number;
    userResponse: UserResponse;
  }> {
    const url = this.API_SERVER + "/api/users/" + userId;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.delete<UserResponse>(url, config);
    return { httpStatus: response.status, userResponse: response.data };
  }

  /**
   * ユーザ編集用 パスワードクリア
   */
  public async usersClearPassword(userId: string): Promise<UserResponse> {
    const url = this.API_SERVER + "/api/users/" + userId + "/clearPassword";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.patch<UserResponse>(url, config);
    return response.data;
  }

  /**
   * 都道府県一覧情報 取得
   */
  public async getPrefList(): Promise<{
    httpStatus: number;
    prefListResponse: PrefListResponse;
  }> {
    const url = this.API_SERVER + "/api/prefs";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<PrefListResponse>(url, config);
    return { httpStatus: response.status, prefListResponse: response.data };
  }
}

export const userApi = new UserApi();
