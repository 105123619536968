






































































































import { userApi, User } from "../api/UserApi";
import { dataTypeApi, DataType } from "../api/DataTypeApi";
import { deliveryTypeApi, DeliveryType } from "../api/DeliveryTypeApi";
import { Component, Vue } from "vue-property-decorator";
import RequestErrorDialog from "./RequestErrorDialog.vue";
import { NETWORK_ERROR } from "../router";

@Component({
  components: {
    "request-error-dialog": RequestErrorDialog
  }
})
export default class ContractList extends Vue {
  users: User[] = [];
  dataTypeList: DataType[] = [];
  deliveryTypeList: DeliveryType[] = [];

  /** v-data-table用のヘッダープロパティ */
  tableHeaders = [
    {
      text: "契約者",
      value: "userName",
      width: "18%"
    },
    { text: "ID", value: "userId", width: "10%" },
    { text: "契約内容", value: "contracts", align: "center" }
  ];

  dialog = {
    requestError: false
  };
  httpStatus = 500;

  clickNew() {
    this.$router.push({ name: "ContractDetail" });
  }

  clickUser(userId: string) {
    this.$router.push({ name: "ContractDetail", params: { userId } });
  }

  clickExit() {
    this.$router.push({ name: "Menu" });
  }

  async mounted() {
    try {
      const result = {
        user: await userApi.getUserList(),
        dataType: await dataTypeApi.getDataTypeList(),
        deliveryType: await deliveryTypeApi.getDeliveryTypeList()
      };
      this.users = result.user.userListResponse.result;
      this.dataTypeList = result.dataType.dataTypeResponse.result.dataTypeList;
      this.deliveryTypeList =
        result.deliveryType.deliveryTypeResponse.result.deliveryTypeList;
    } catch (error) {
      this.users = [];
      let newError: Error;
      if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: newError.message });
      }
    }
  }
}
