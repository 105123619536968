var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-base",attrs:{"id":"contract-list"}},[_c('v-card',{staticClass:"s-panel",attrs:{"max-width":"1200"}},[_c('v-card-title',[_c('div',{staticClass:"s-title"},[_vm._v("契約者一覧画面")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"secondary","width":"100"},on:{"click":function($event){return _vm.clickNew()}}},[_vm._v("新規")])],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"contract-list-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.users,"hide-default-header":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{staticClass:"white--text text-h5 pl-4",attrs:{"rowspan":"3","width":"15%"}},[_vm._v(" 契約者 ")]),_c('th',{staticClass:"white--text text-h5 px-0 text-center",attrs:{"rowspan":"3","width":"15%"}},[_vm._v(" ＩＤ ")]),_c('th',{staticClass:"white--text text-h6 text-center",staticStyle:{"border-bottom":"1px solid #ffffff"},attrs:{"colspan":_vm.dataTypeList.length * _vm.deliveryTypeList.length}},[_vm._v(" 契約内容 ")])]),_c('tr',_vm._l((_vm.dataTypeList),function(dataType){return _c('th',{key:dataType.code,staticClass:"white--text text-subtitile-1 px-0 text-center px-1",staticStyle:{"border-bottom":"1px solid #ffffff"},attrs:{"colspan":_vm.deliveryTypeList.length}},[_vm._v(" "+_vm._s(dataType.name)+" ")])}),0),_c('tr',{staticClass:"px-1"},[_vm._l((_vm.dataTypeList),function(dataType){return _vm._l((_vm.deliveryTypeList),function(deliv){return _c('th',{key:dataType.code + '_' + deliv.code,staticClass:"white--text text-body-2 px-0 text-center"},[_vm._v(" "+_vm._s(deliv.name)+" ")])})})],2)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.clickUser(item.userId)}}},[_c('td',[_vm._v(_vm._s(item.userName))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.userId))]),_vm._l((_vm.dataTypeList),function(dataType){return _vm._l((_vm.deliveryTypeList),function(deliv){return _c('td',{key:dataType.code + '_' + deliv.code,staticClass:"text-center"},[(
                        item.contracts &&
                          item.contracts.find(
                            function (it) { return it.dataType === dataType.code &&
                              it.delivCd === deliv.code; }
                          )
                      )?_c('span',[_vm._v("〇")]):_vm._e()])})})],2)]}}])})],1)],1),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","width":"100"},on:{"click":function($event){return _vm.clickExit()}}},[_vm._v("戻る")])],1)],1),_c('request-error-dialog',{attrs:{"requestErrorDialogFlag":_vm.dialog.requestError,"httpStatus":_vm.httpStatus,"message":""},on:{"update:requestErrorDialogFlag":function($event){return _vm.$set(_vm.dialog, "requestError", $event)},"update:request-error-dialog-flag":function($event){return _vm.$set(_vm.dialog, "requestError", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }