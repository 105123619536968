import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import { ServerDataList } from "@/store/ServerModule";
import loginModule from "@/store/LoginModule";

export interface ServerDataListResponse extends ApiResponse {
  result: {
    serverDataList: ServerDataList;
  };
}

class ServerDataApi {
  API_SERVER = config.api.access_url;

  /**
   * サーバデータリスト取得
   * @return {Promise<string, ServerDataListResponse>} httpステータス・APIレスポンス・サーバデータリスト
   */
  public async getServerDataList(): Promise<{
    httpStatus: number;
    serverDataListResponse: ServerDataListResponse;
  }> {
    const url = this.API_SERVER + "/api/servers/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<ServerDataListResponse>(url, config);
    return {
      httpStatus: response.status,
      serverDataListResponse: response.data
    };
  }
}

export const serverDataApi = new ServerDataApi();
