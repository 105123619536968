import configJson from "../assets/Config.json";
import messageConfig from "@/assets/MessageConfig.json";
type MessageConfig = typeof messageConfig;

export const config = configJson;

/*
 * メッセージクラス
 */
class Message {
  messageConfig: MessageConfig;

  /**
   * コンストラクタ
   */
  constructor(messageConfig: MessageConfig) {
    this.messageConfig = messageConfig;
  }

  /**
   * メッセージ取得
   * @param code メッセージCODE
   */
  public getMessage(code: keyof MessageConfig): string {
    return this.messageConfig[code];
  }

  /**
   * メッセージ取得
   * @param code メッセージCODE
   * @param list %s 置換用文字列
   */
  public getMessageAndReplace(
    code: keyof MessageConfig,
    list: string | string[]
  ): string {
    let message = "";

    // 配列ではない場合、文字列として配列に格納
    let newList: string[] = [];
    if (list instanceof Array) {
      newList = list;
    } else {
      newList.push(list);
    }

    if (typeof this.messageConfig[code] === undefined) {
      // メッセージCodeからメッセージが取得できなければ、文字列を格納
      message += newList[0];
    } else {
      // %s を文字列と置換
      message = this.messageConfig[code];
      for (let i = 0; i < newList.length; i++) {
        message = message.replace("%s", newList[i]);
      }
    }

    // 不要な %s を削除
    message = message.replace(/%s/g, "");
    return message;
  }
}

export const message = new Message(messageConfig);
