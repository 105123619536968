import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { useTypeApi, UseTypeResponse } from "@/api/UseTypeApi";
import { NETWORK_ERROR } from "@/router";

export interface UseTypeModuleBase {
  /**
   * 用途種別リスト
   */
  useTypeList: UseTypeList;
}
export type UseTypeList = {
  code: number;
  name: string;
}[];
@Module({
  dynamic: true,
  store: store,
  name: "UseTypeModule",
  namespaced: true
})
class UseTypeModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements UseTypeModuleBase {
  /**
   * 用途種別リスト
   */
  public useTypeList = [
    {
      code: 0,
      name: ""
    }
  ];

  /**
   * 用途種別リストをStoreに格納
   * @param {useTypeList} useTypeList - 用途種別リスト
   */
  @Mutation
  public commitUseTypeList(useTypeList: UseTypeList): void {
    this.useTypeList = useTypeList;
  }

  /**
   * 用途種別リストの取得
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getUseTypeList(): Promise<boolean> {
    let httpStatus: number;
    let useTypeResponse: UseTypeResponse;
    let useTypeList: UseTypeList;
    try {
      const result = await useTypeApi.getUseTypeList();
      httpStatus = result.httpStatus;
      useTypeResponse = result.useTypeResponse;
      useTypeList = useTypeResponse.result.useTypeList;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitUseTypeList(useTypeList);
      return true;
    } else {
      return false;
    }
  }
}

const useTypeModule: UseTypeModule = getModule(UseTypeModule);
export default useTypeModule;
