



















import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

@Component
export default class MessageDialog extends Vue {
  /** ダイアログ表示フラグ */
  @PropSync("messageDialogFlag", { type: Boolean, default: false })
  messageDialog!: boolean;

  /** ダイアログテキスト */
  @Prop({ type: Object })
  dialogText!: {
    title: string;
    message: string;
  };
}
