import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";
import {
  ErrorSettingData,
  ErrorSettingInput
} from "@/store/ErrorSettingModule";

/**
 * 障害設定情報リストレスポンス
 */
export interface ErrorSettingListResponse extends ApiResponse {
  result: {
    errorSettingList: ErrorSettingData[];
  };
}

class ErrorSettingApi {
  API_SERVER = config.api.access_url;

  /**
   * 障害データリスト取得
   * @return {Promise<string, ErrorSettingListResponse>} httpステータス・APIレスポンス・障害データリスト
   */
  public async getErrorSettingList(): Promise<{
    httpStatus: number;
    errorSettingResponse: ErrorSettingListResponse;
  }> {
    const url = this.API_SERVER + "/api/error-settings/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<ErrorSettingListResponse>(url, config);
    return {
      httpStatus: response.status,
      errorSettingResponse: response.data
    };
  }

  /**
   * 障害データ削除
   * @return {Promise<string, ApiResponse>} httpステータス・APIレスポンス
   */
  public async deleteErrorSetting(
    errorSettingId: number
  ): Promise<{
    httpStatus: number;
    errorSettingResponse: ApiResponse;
  }> {
    const url = this.API_SERVER + "/api/error-settings/" + `${errorSettingId}`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.delete<ApiResponse>(url, config);
    return {
      httpStatus: response.status,
      errorSettingResponse: response.data
    };
  }

  /**
   * 障害データ作成
   * @return {Promise<string, ApiResponse>} httpステータス・APIレスポンス・障害データ
   */
  public async createErrorSetting(
    errorSettingInput: ErrorSettingInput
  ): Promise<{
    httpStatus: number;
    errorSettingResponse: ApiResponse;
  }> {
    const data = JSON.stringify(errorSettingInput);
    const url = this.API_SERVER + "/api/error-settings/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.post<ApiResponse>(url, data, config);
    return {
      httpStatus: response.status,
      errorSettingResponse: response.data
    };
  }
}

export const errorSettingApi = new ErrorSettingApi();
