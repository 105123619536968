



























































import { Component, Vue } from "vue-property-decorator";
import errorSettingModule, {
  ErrorSettingData
} from "../store/ErrorSettingModule";
import useTypeModule, { UseTypeList } from "../store/UseTypeModule";
import MessageDialog from "./MessageDialog.vue";
import ErrSttInputDialog from "./ErrSttInputDialog.vue";
import RequestErrorDialog from "./RequestErrorDialog.vue";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog.vue";

@Component({
  components: {
    "delete-dialog": DeleteConfirmationDialog,
    "message-dialog": MessageDialog,
    "input-dialog": ErrSttInputDialog,
    "request-error-dialog": RequestErrorDialog
  }
})
export default class ErrorSetting extends Vue {
  /** v-data-table用のヘッダープロパティ */
  tableHeaders = [
    { text: "検知文字", value: "detectKeyword", width: "50%" },
    { text: "対象サーバ", value: "useTypeName", width: "30%" },
    { text: "削除", value: "delete", align: "center", sortable: false }
  ];

  /** 各ダイアログのフラグ値 */
  dialog = {
    delete: false,
    input: false,
    success: false,
    message: false,
    requestError: false
  };

  /** 画面遷移時のデータ読み込みエラー */
  mountedOK = false;

  /** httpステータスコード */
  httpStatus = 500;

  /** エラーダイアログのテキスト */
  requestErrorDialogMessage = "";

  /** メッセージダイアログのテキスト */
  messageDialogText = {
    title: "削除完了",
    message: "削除が完了しました。"
  };

  /** ダイアログのサンプルテキスト */
  dialogTemplate = {
    messageDialog: {
      succeedRegister: {
        title: "登録完了",
        message: "登録が完了しました。"
      },
      failRegisterForInputError: {
        title: "登録失敗",
        message: "登録に失敗しました。入力内容をご確認ください。"
      },
      succeedDelete: {
        title: "削除完了",
        message: "削除が完了しました。"
      }
    },

    requestErrorDialog: {
      failDelete: "削除に失敗しました。",
      failRegister: "登録に失敗しました。"
    }
  };

  /** 障害設定の入力情報 */
  errorSettingInput: {
    detectKeyword: string | null;
    useTypeCode: number | null;
  } = {
    detectKeyword: null,
    useTypeCode: null
  };

  /** 削除する障害設定情報の障害設定ID */
  errorSettingIdOfDeleted = 0;

  /**
   * 障害設定情報リストをStoreから取得する。
   * @returns {ErrorSettingData[]} 障害設定情報リスト
   */
  get errorSettingList(): ErrorSettingData[] {
    if (this.httpStatus !== 200 && this.mountedOK !== true) {
      return [];
    }
    return errorSettingModule.errorSettingList;
  }

  /**
   * 用途種別リストをStoreから取得する。
   * @returns {UseTypeList} 用途種別リスト
   */
  get useTypeList(): UseTypeList {
    return useTypeModule.useTypeList;
  }

  /**
   * 障害設定情報をDBから削除する。
   */
  async deleteErrorSetting(): Promise<void> {
    let result = false;
    try {
      result = await errorSettingModule.deleteErrorSetting(
        this.errorSettingIdOfDeleted
      );
    } catch (error) {
      this.requestErrorDialogMessage = this.dialogTemplate.requestErrorDialog.failDelete;
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (error.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
    if (result === true) {
      this.messageDialogText = this.dialogTemplate.messageDialog.succeedDelete;
      this.dialog.message = true;
      this.loadAllDataToStore();
    }
  }

  /**
   * 削除する障害設定情報のIDを保存し、削除確認ダイアログを表示する。
   * @param {number} errorSettingId 削除する障害設定情報のID
   */
  confirmDelete(errorSettingId: number): void {
    this.errorSettingIdOfDeleted = errorSettingId;
    this.dialog.delete = true;
  }

  /**
   * 障害設定情報をDBに登録する。
   */
  async registerErrorSetting(): Promise<void> {
    // 障害設定情報の値が全て入力されていない場合
    if (
      this.errorSettingInput.detectKeyword === null ||
      this.errorSettingInput.useTypeCode === null
    ) {
      this.messageDialogText = this.dialogTemplate.messageDialog.failRegisterForInputError;
      this.dialog.message = true;
      return;
    }

    let result = false;
    try {
      this.dialog.input = false;
      result = await errorSettingModule.createErrorSetting({
        detectKeyword: this.errorSettingInput.detectKeyword,
        useTypeCode: this.errorSettingInput.useTypeCode
      });
    } catch (error) {
      this.requestErrorDialogMessage = this.dialogTemplate.requestErrorDialog.failRegister;
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (error.message === "409") {
        // 409 Conflict Error
        this.httpStatus = 409;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
      return;
    }
    if (result === true) {
      this.messageDialogText = this.dialogTemplate.messageDialog.succeedRegister;
      this.dialog.message = true;
      this.loadAllDataToStore();
    }
  }

  /**
   * ひとつ前のページに遷移する。
   */
  goToPreviousPage(): void {
    this.$router.back();
  }

  /**
   * 各データをDBからStoreに取り込む
   */
  async loadAllDataToStore(): Promise<void> {
    const result = {
      errorSetting: false,
      useType: false
    };
    try {
      result.errorSetting = await errorSettingModule.getErrorSettingList();
      result.useType = await useTypeModule.getUseTypeList();
    } catch (error) {
      this.requestErrorDialogMessage = "";
      if (error.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (error.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: error.message });
      }
    }
    if (result.errorSetting === true && result.useType === true) {
      this.httpStatus = 200;
    }
  }

  /**
   * 障害設定画面に遷移した時に実行
   */
  async mounted() {
    await this.loadAllDataToStore();
    if (this.httpStatus === 200) {
      this.mountedOK = true;
    }
  }
}
