import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "ts-polyfill/lib/es2016-array-include";
import "ts-polyfill/lib/es2017-object";
import "ts-polyfill/lib/es2017-string";
import "ts-polyfill/lib/es2018-async-iterable"; // for-await-of
import "ts-polyfill/lib/es2018-promise";
import "ts-polyfill/lib/es2019-array";
import "ts-polyfill/lib/es2019-object";
import "ts-polyfill/lib/es2019-string";
import "ts-polyfill/lib/es2020-string";

import * as log from "loglevel";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
