import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { errorApi, ErrorListResponse, ErrorDataResponse } from "@/api/ErrorApi";
import { NETWORK_ERROR } from "@/router";

export interface ErrorModuleBase {
  /**
   * 障害データリスト
   */
  errorList: ErrorDataList;

  /**
   * 障害データ
   */
  errorData: ErrorData;
}

export type ErrorDataList = {
  errorId: number;
  detectDateTime: string;
  detectKeyword: string;
  serverName: string;
  statusCode: number;
  statusName: string;
  memo: string;
  useTypeCode: number;
  logFile: string;
}[];

export type ErrorData = {
  errorId: number;
  errorDetail?: string;
  statusCode: number;
  memo: string;
};

@Module({ dynamic: true, store: store, name: "ErrorModule", namespaced: true })
class ErrorModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements ErrorModuleBase {
  /**
   * 障害データリスト
   */
  public errorList = [
    {
      errorId: 99,
      detectDateTime: "",
      detectKeyword: "",
      serverName: "",
      statusCode: 99,
      statusName: "",
      memo: "",
      useTypeCode: 99,
      logFile: ""
    }
  ];

  /**
   * 障害データ
   */
  public errorData: ErrorData = {
    errorId: -1,
    errorDetail: "",
    statusCode: 99,
    memo: ""
  };

  /**
   * 障害データリストをStoreに格納
   * @param {ErrorDataList} errorList - 障害データリスト
   */
  @Mutation
  public commitErrorList(errorList: ErrorDataList): void {
    this.errorList = errorList;
  }

  /**
   * 障害データをStoreに格納
   * @param {ErrorData} errorData - 障害データ
   */
  @Mutation
  public commitErrorData(errorData: ErrorData): void {
    this.errorData = errorData;
  }

  /**
   * 障害データリストの取得
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getErrorList(): Promise<boolean> {
    let httpStatus: number;
    let errorResponse: ErrorListResponse;
    let errorList: ErrorDataList;
    try {
      const result = await errorApi.getErrorList();
      httpStatus = result.httpStatus;
      errorResponse = result.errorResponse;
      errorList = errorResponse.result.errorList;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitErrorList(errorList);
      return true;
    } else {
      return false;
    }
  }

  /**
   * 障害データの取得
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getErrorData(errorId: number): Promise<boolean> {
    let httpStatus: number;
    let errorResponse: ErrorDataResponse;
    let errorData: ErrorData;
    try {
      const result = await errorApi.getErrorData(errorId);
      httpStatus = result.httpStatus;
      errorResponse = result.errorResponse;
      errorData = errorResponse.result.errorData;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitErrorData(errorData);
      return true;
    } else {
      return false;
    }
  }

  /**
   * 障害データの編集
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async editErrorData(errorDataInput: ErrorData): Promise<boolean> {
    let httpStatus: number;
    try {
      const result = await errorApi.editErrorData(errorDataInput);
      httpStatus = result.httpStatus;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      return true;
    } else {
      return false;
    }
  }
}

const errorModule: ErrorModule = getModule(ErrorModule);
export default errorModule;
