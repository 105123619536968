

































import { Component, Vue } from "vue-property-decorator";
import loginModule from "../store/LoginModule";

@Component
export default class DefaultLayout extends Vue {
  get userName(): string {
    if (loginModule.userName === "") {
      return "no-name";
    } else {
      return loginModule.userName;
    }
  }

  async logout() {
    await loginModule.reset();
    await this.$router.push({ name: "Login" });
  }
}
