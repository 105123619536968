import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";

export interface DataTypeResponse extends ApiResponse {
  result: {
    dataTypeList: DataType[];
  };
}

export type DataType = {
  code: number;
  name: string;
};

class DataTypeApi {
  API_SERVER = config.api.access_url;

  /**
   * データ種別リスト取得
   * @return {Promise<string, DataTypeResponse>} httpステータス・APIレスポンス・データ種別リスト
   */
  public async getDataTypeList(): Promise<{
    httpStatus: number;
    dataTypeResponse: DataTypeResponse;
  }> {
    const url = this.API_SERVER + "/api/data-types/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<DataTypeResponse>(url, config);
    return {
      httpStatus: response.status,
      dataTypeResponse: response.data
    };
  }
}

export const dataTypeApi = new DataTypeApi();
