import {
  errorSettingApi,
  ErrorSettingListResponse
} from "@/api/ErrorSettingApi";
import { NETWORK_ERROR } from "@/router";
import store from "@/store";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from "vuex-module-decorators";

export interface ErrorSettingModuleBase {
  /**
   * 障害設定情報リスト
   */
  errorSettingList: ErrorSettingData[];
}

/**
 * 障害設定情報の型
 */
export type ErrorSettingData = {
  errorSettingId: number;
  detectKeyword: string;
  useTypeCode: number;
  useTypeName: string;
};

/**
 * 障害設定情報作成用の入力型
 */
export type ErrorSettingInput = {
  detectKeyword: string;
  useTypeCode: number;
};

@Module({
  dynamic: true,
  store: store,
  name: "ErrorSettingModule",
  namespaced: true
})
class ErrorSettingModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements ErrorSettingModuleBase {
  /**
   * 障害設定情報リスト
   */
  public errorSettingList = [
    {
      errorSettingId: -99,
      detectKeyword: "",
      useTypeCode: 0,
      useTypeName: ""
    }
  ];

  /**
   * 障害設定情報リストをStoreに格納
   * @param {ErrorSettingList} errorSettingList - 障害設定情報リスト
   */
  @Mutation
  public commitErrorSettingList(errorSettingList: ErrorSettingData[]): void {
    this.errorSettingList = errorSettingList;
  }

  /**
   * 障害設定情報リストの取得
   * ※アクションメソッドからエラーを投げるので、rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getErrorSettingList(): Promise<boolean> {
    let httpStatus: number;
    let errorSettingResponse: ErrorSettingListResponse;
    let errorSettingList: ErrorSettingData[];
    try {
      const result = await errorSettingApi.getErrorSettingList();
      httpStatus = result.httpStatus;
      errorSettingResponse = result.errorSettingResponse;
      errorSettingList = errorSettingResponse.result.errorSettingList;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitErrorSettingList(errorSettingList);
      return true;
    } else {
      return false;
    }
  }

  /**
   * 障害設定情報の削除
   * ※アクションメソッドからエラーを投げるので、rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async deleteErrorSetting(errorSettingId: number): Promise<boolean> {
    let httpStatus: number;
    try {
      const result = await errorSettingApi.deleteErrorSetting(errorSettingId);
      httpStatus = result.httpStatus;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 障害設定情報の作成
   * ※アクションメソッドからエラーを投げるので、rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async createErrorSetting(
    errorSettingInput: ErrorSettingInput
  ): Promise<boolean> {
    let httpStatus: number;
    try {
      const result = await errorSettingApi.createErrorSetting(
        errorSettingInput
      );
      httpStatus = result.httpStatus;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      return true;
    } else {
      return false;
    }
  }
}
const errorSettingModule: ErrorSettingModule = getModule(ErrorSettingModule);
export default errorSettingModule;
