import Vue from "vue";
import Vuex from "vuex";

import { LoginModuleBase } from "@/store/LoginModule";
import { ServerModuleBase } from "@/store/ServerModule";
import { ErrorModuleBase } from "@/store/ErrorModule";
import { UseTypeModuleBase } from "@/store/UseTypeModule";
import { ErrorStatusModuleBase } from "@/store/ErrorStatusModule";
import { NoticeModuleBase } from "@/store/NoticeModule";

Vue.use(Vuex);

export interface StoreType {
  loginModule: LoginModuleBase;
  serverModule: ServerModuleBase;
  errorModule: ErrorModuleBase;
  useTypeModuleBase: UseTypeModuleBase;
  errorStatusModuleBase: ErrorStatusModuleBase;
  notice: NoticeModuleBase;
}

export default new Vuex.Store<StoreType>({
  strict: process.env.NODE_ENV !== "production"
});
