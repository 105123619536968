import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";

export interface LoginResponse extends ApiResponse {
  result: {
    token: string;
    id: number;
    name: string;
  };
}

class LoginApi {
  API_SERVER = config.api.access_url;

  /**
   * ログイン認証
   */
  public async login(
    loginId: string,
    password: string
  ): Promise<{ httpStatus: number; loginResponse: LoginResponse }> {
    const url = this.API_SERVER + "/api/auth/login";
    const data = JSON.stringify({ login: loginId, password: password });
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const response = await axios.post<LoginResponse>(url, data, config);
    return { httpStatus: response.status, loginResponse: response.data };
  }
}

export const loginApi = new LoginApi();
