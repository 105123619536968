











































import {
  Component,
  Vue,
  PropSync,
  Emit,
  Prop,
  Watch
} from "vue-property-decorator";
import { UseTypeList } from "../store/UseTypeModule";

@Component
export default class ErrSttInputDialog extends Vue {
  /** ダイアログ表示フラグ */
  @PropSync("inputDialogFlag", { type: Boolean, default: false })
  inputDialog!: boolean;

  /** 障害設定情報の入力値 */
  @PropSync("errorSettingInputData", { type: Object })
  errorSettingInput!: {
    detectKeyword: string | null;
    useTypeCode: number | null;
  };

  /** 用途種別リスト */
  @Prop({ type: Array })
  useTypeList!: UseTypeList;

  /**
   * 障害設定情報をDBに登録する際の処理
   * ※登録処理は親コンポーネントの関数で行う。
   */
  @Emit("registerErrorSetting")
  registerErrorSetting(): void {
    return;
  }

  /**
   * ダイアログを閉じた際に入力値を初期化
   */
  @Watch("inputDialog")
  clearInput(): void {
    this.errorSettingInput.detectKeyword = null;
    this.errorSettingInput.useTypeCode = null;
  }
}
