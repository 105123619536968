




















import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

export type DialogText = {
  title: string;
  errorMessage: string;
};

@Component
export default class RequestErrorDialog extends Vue {
  /** ダイアログ表示フラグ */
  @PropSync("requestErrorDialogFlag", { type: Boolean, default: false })
  requestErrorDialog!: boolean;

  /** httpステータスコード */
  @Prop({ type: Number })
  httpStatus!: number;

  /** ダイアログテキスト */
  @Prop({ type: String })
  message!: string;

  /**
   * ダイアログを閉じて、遷移処理を必要であれば実行する。
   */
  closeDialog(): void {
    this.requestErrorDialog = false;
    if (this.httpStatus === 401) {
      this.$router.push({ name: "Login" });
    } else if (this.httpStatus === 404) {
      if (this.$route.name === "ErrorDetail") {
        this.$router.back();
      }
    }
  }

  /**
   * エラーダイアログのテキスト
   * @returns {DialogText} ダイアログのテキスト
   */
  get dialogText(): DialogText {
    if (this.httpStatus === 500) {
      return {
        title: "500エラー",
        errorMessage: "サーバエラーです。通信状況をご確認ください。"
      };
    } else if (this.httpStatus === 404) {
      return {
        title: "404エラー",
        errorMessage: "リクエストエラーです。データを選択しなおしてください"
      };
    } else if (this.httpStatus === 401) {
      return {
        title: "401エラー",
        errorMessage: "管理者権限エラーです。再度、ログインしてください。"
      };
    } else if (this.httpStatus === 409) {
      if (this.$route.name === "ContractDetail") {
        return {
          title: "409エラー",
          errorMessage: "既にこのユーザIDは登録されています。"
        };
      }
      return {
        title: "409エラー",
        errorMessage: "既にこのデータは登録されています。"
      };
    } else if (this.httpStatus === 400) {
      if (this.$route.name === "ContractDetail") {
        return {
          title: "400エラー",
          errorMessage: "IDを入力してください。"
        };
      }
      return {
        title: "400エラー",
        errorMessage: "不正なリクエストです。"
      };
    } else {
      return {
        title: "エラー",
        errorMessage: "エラーが発生しました。"
      };
    }
  }
}
