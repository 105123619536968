import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";
import { NoticeData } from "@/store/NoticeModule";

/**
 * 配信お知らせ情報レスポンス
 */
export interface NoticeResponse extends ApiResponse {
  result: {
    notice: NoticeData;
  };
}

class NoticeApi {
  API_SERVER = config.api.access_url;

  /**
   * 配信お知らせ情報取得
   * @return {Promise<string, NoticeResponse>} httpステータス・APIレスポンス・配信お知らせ情報
   */
  public async getNotice(): Promise<{
    httpStatus: number;
    noticeResponse: NoticeResponse;
  }> {
    const url = this.API_SERVER + "/api/notice/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<NoticeResponse>(url, config);
    return {
      httpStatus: response.status,
      noticeResponse: response.data
    };
  }

  /**
   * 配信お知らせ情報編集
   * @return {Promise<string, NoticeResponse>} httpステータス・APIレスポンス・配信お知らせ情報
   */
  public async editNotice(
    noticeInput: NoticeData
  ): Promise<{
    httpStatus: number;
    noticeResponse: NoticeResponse;
  }> {
    const data = JSON.stringify(noticeInput);
    const url = this.API_SERVER + "/api/notice/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.put<NoticeResponse>(url, data, config);
    return {
      httpStatus: response.status,
      noticeResponse: response.data
    };
  }
}

export const noticeApi = new NoticeApi();
