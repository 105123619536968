import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { noticeApi, NoticeResponse } from "@/api/NoticeApi";
import { NETWORK_ERROR } from "@/router";

export interface NoticeModuleBase {
  /**
   * 配信お知らせ情報
   */
  notice: NoticeData;
}

export type NoticeData = {
  notice: string;
};

@Module({ dynamic: true, store: store, name: "NoticeModule", namespaced: true })
class NoticeModule<S = ThisType<any>, R = any> extends VuexModule<S, R>
  implements NoticeModuleBase {
  /**
   * 配信お知らせ情報
   */
  public notice: NoticeData = {
    notice: ""
  };

  /**
   * 配信お知らせ情報をStoreに格納
   * @param {NoticeData} notice - 配信お知らせ情報
   */
  @Mutation
  public commitNotice(notice: NoticeData): void {
    this.notice = notice;
  }

  /**
   * 配信お知らせ情報の取得
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async getNotice(): Promise<boolean> {
    let httpStatus: number;
    let noticeResponse: NoticeResponse;
    let notice: NoticeData;
    try {
      const result = await noticeApi.getNotice();
      httpStatus = result.httpStatus;
      noticeResponse = result.noticeResponse;
      notice = noticeResponse.result.notice;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      this.commitNotice(notice);
      return true;
    } else {
      return false;
    }
  }

  /**
   * 配信お知らせ情報の編集
   * ※アクションメソッドからエラーを投げるので rawError: true の指定が必要
   */
  @Action({ rawError: true })
  public async editNotice(noticeInput: NoticeData): Promise<boolean> {
    let httpStatus: number;
    try {
      const result = await noticeApi.editNotice(noticeInput);
      httpStatus = result.httpStatus;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.status.toString());
      } else {
        throw new Error(NETWORK_ERROR);
      }
    }
    if (httpStatus === 200) {
      return true;
    } else {
      return false;
    }
  }
}

const noticeModule: NoticeModule = getModule(NoticeModule);
export default noticeModule;
