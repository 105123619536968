






















































































































































































import { Component, Vue } from "vue-property-decorator";
import { userApi, Contract, Pref } from "../api/UserApi";
import { NETWORK_ERROR } from "../router";
import MessageDialog from "./MessageDialog.vue";
import RequestErrorDialog from "./RequestErrorDialog.vue";
import { dataTypeApi, DataType } from "../api/DataTypeApi";
import { deliveryTypeApi, DeliveryType } from "../api/DeliveryTypeApi";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog.vue";

@Component({
  components: {
    "message-dialog": MessageDialog,
    "delete-dialog": DeleteConfirmationDialog,
    "request-error-dialog": RequestErrorDialog
  }
})
export default class ContractDetail extends Vue {
  /** ダイアログの表示フラグ */
  dialog = {
    message: false,
    delete: false,
    requestError: false
  };

  /** httpステータスコード */
  httpStatus = 500;

  /** リクエストエラーダイアログのテキスト */
  requestErrorDialogMessage = "";

  /** メッセージダイアログのテキスト */
  messageDialogText = {
    title: "登録完了",
    message: "登録が完了しました。"
  };

  // パスワード表示
  dispPass = true;

  // 契約者情報新規作成フラグ
  makingNewContract = true;

  // 契約選択
  dispSelect = false;

  // パスワード初期化
  dispInitPass = false;

  userName = "";
  userId = "";
  password = "";
  contracts: Contract[] = [];

  passEditFlg = false;

  prefList: Pref[] = [];
  dataTypeList: DataType[] = [];
  deliveryTypeList: DeliveryType[] = [];

  contractList(): string[] {
    const contractList: string[] = [];
    this.contracts.forEach(contract => {
      if (contract.prefCodeList.length > 0) {
        const prefNames = contract.prefCodeList.reduce((tmp, prefCode) => {
          const pref = this.prefList.find(pref => pref.prefCd === prefCode);
          if (pref !== undefined && pref !== null) {
            const prefName = pref.prefName;
            return tmp + "　" + prefName;
          } else {
            return tmp;
          }
        }, "");
        const type = this.dataTypeList.find(
          dataType => dataType.code === contract.dataType
        );
        const deliv = this.deliveryTypeList.find(
          deliv => deliv.code === contract.delivCd
        );
        if (type !== undefined && deliv !== undefined) {
          contractList.push(type.name + "（" + deliv.name + "）　" + prefNames);
        }
      }
    });
    return contractList;
  }

  clickRegist() {
    if (this.$route.params.userId !== undefined) {
      this.editUser();
    } else {
      this.createUser();
    }
  }

  async createUser() {
    try {
      if (this.userId === "") {
        throw new Error("400");
      }
      const result = await userApi.createUser({
        userId: this.userId,
        userName: this.userName,
        passEditFlg: true,
        password: this.password,
        contracts: this.contracts.filter(
          contract => contract.prefCodeList.length > 0
        )
      });
      const httpStatus = result.httpStatus;

      if (httpStatus === 200) {
        this.$router.push({ name: "ContractList" });
      }
    } catch (error) {
      let newError: Error;
      if (error.message === "400") {
        newError = new Error("400");
      } else if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      this.requestErrorDialogMessage = "登録に失敗しました。";
      if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (newError.message === "400") {
        // 400 Bad Request
        this.httpStatus = 400;
        this.dialog.requestError = true;
      } else if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (newError.message === "409") {
        // 409 Conflict Error
        this.httpStatus = 409;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: newError.message });
      }
    }
  }

  async editUser() {
    try {
      if (this.userId === "") {
        throw new Error("400");
      }
      const preUserId = this.$route.params.userId;
      const result = await userApi.editUser(preUserId, {
        userId: this.userId,
        userName: this.userName,
        passEditFlg: this.passEditFlg,
        password: this.password,
        contracts: this.contracts.filter(
          contract => contract.prefCodeList.length > 0
        )
      });
      const httpStatus = result.httpStatus;

      if (httpStatus === 200) {
        this.$router.push({ name: "ContractList" });
      }
    } catch (error) {
      let newError: Error;
      if (error.message === "400") {
        newError = new Error("400");
      } else if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      this.requestErrorDialogMessage = "登録に失敗しました。";
      if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (newError.message === "400") {
        // 400 Bad Request
        this.httpStatus = 400;
        this.dialog.requestError = true;
      } else if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (newError.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.dialog.requestError = true;
      } else if (newError.message === "409") {
        // 409 Conflict Error
        this.httpStatus = 409;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: newError.message });
      }
    }
  }

  async deleteUser() {
    try {
      const preUserId = this.$route.params.userId;
      const result = await userApi.deleteUser(preUserId);
      const httpStatus = result.httpStatus;

      if (httpStatus === 200) {
        this.$router.push({ name: "ContractList" });
      }
    } catch (error) {
      let newError: Error;
      if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      this.requestErrorDialogMessage = "削除に失敗しました。";
      if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (newError.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: newError.message });
      }
    }
  }

  async clearPassword() {
    this.dispInitPass = true;
    this.dispPass = true;
    this.passEditFlg = true;

    // パスワードを乱数で決める
    const str =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const passLen = 12;
    this.password = Array.from(Array(passLen))
      .map(() => str[Math.floor(Math.random() * str.length)])
      .join("");
  }

  clickBack() {
    this.$router.push({ name: "ContractList" });
  }

  async mounted() {
    if (this.$route.params.userId) {
      this.makingNewContract = false;
      this.userId = this.$route.params.userId;
      try {
        const result = await userApi.getUser(this.$route.params.userId);
        const httpStatus = result.httpStatus;
        const user = result.userResponse.result;

        if (httpStatus === 200) {
          this.userName = user.userName;
          this.userId = user.userId;
          this.password = user.password;
          this.dispPass = !user.passEditFlg;
          this.contracts = user.contracts;
        }
      } catch (error) {
        let newError: Error;
        if (error.response) {
          newError = new Error(error.response.status.toString());
        } else {
          newError = new Error(NETWORK_ERROR);
        }
        if (newError.message === "500") {
          // 500 Internal Server Error
          this.httpStatus = 500;
          this.dialog.requestError = true;
        } else if (newError.message === "401") {
          // 401 Unauthorized
          this.httpStatus = 401;
          this.dialog.requestError = true;
        } else if (newError.message === "404") {
          // 404 Not Found
          this.httpStatus = 404;
          this.dialog.requestError = true;
        } else {
          // 運用上起こってはいけないエラー
          this.$router.push({ name: newError.message });
        }
      }
    }

    try {
      const result = {
        prefList: await userApi.getPrefList(),
        dataTypeList: await dataTypeApi.getDataTypeList(),
        deliveryTypeList: await deliveryTypeApi.getDeliveryTypeList()
      };
      this.prefList = result.prefList.prefListResponse.result;
      this.dataTypeList =
        result.dataTypeList.dataTypeResponse.result.dataTypeList;
      this.deliveryTypeList =
        result.deliveryTypeList.deliveryTypeResponse.result.deliveryTypeList;
    } catch (error) {
      let newError: Error;
      if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else {
        // 運用上起こってはいけないエラー
        this.$router.push({ name: newError.message });
      }
    }

    // 契約情報作成
    for (let i = 0; i < this.dataTypeList.length; i++) {
      for (let j = 0; j < this.deliveryTypeList.length; j++) {
        const target = this.contracts.find(
          contract =>
            contract.dataType === this.dataTypeList[i].code &&
            contract.delivCd === this.deliveryTypeList[j].code
        );
        if (target === undefined) {
          this.contracts.push({
            dataType: this.dataTypeList[i].code,
            delivCd: this.deliveryTypeList[j].code,
            prefCodeList: []
          });
        }
      }
    }
  }
}
