import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";

import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";
import { ErrorDataList, ErrorData } from "@/store/ErrorModule";

/**
 * 障害データリストレスポンス
 */
export interface ErrorListResponse extends ApiResponse {
  result: {
    errorList: ErrorDataList;
  };
}

/**
 * 障害データレスポンス
 */
export interface ErrorDataResponse extends ApiResponse {
  result: {
    errorData: ErrorData;
  };
}

class ErrorApi {
  API_SERVER = config.api.access_url;

  /**
   * 障害データリスト取得
   * @return {Promise<string, ErrorListResponse>} httpステータス・APIレスポンス・障害データリスト
   */
  public async getErrorList(): Promise<{
    httpStatus: number;
    errorResponse: ErrorListResponse;
  }> {
    const url = this.API_SERVER + "/api/errors/";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<ErrorListResponse>(url, config);
    return {
      httpStatus: response.status,
      errorResponse: response.data
    };
  }

  /**
   * 障害データ取得
   * @return {Promise<string, ErrorDataResponse>} httpステータス・APIレスポンス・障害データ
   */
  public async getErrorData(
    errorId: number
  ): Promise<{
    httpStatus: number;
    errorResponse: ErrorDataResponse;
  }> {
    const url = this.API_SERVER + "/api/errors/" + `${errorId}`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<ErrorDataResponse>(url, config);
    return {
      httpStatus: response.status,
      errorResponse: response.data
    };
  }

  /**
   * 障害データ編集
   * @return {Promise<string, ErrorDataResponse>} httpステータス・APIレスポンス・障害データ
   */
  public async editErrorData(
    errorDataInput: ErrorData
  ): Promise<{
    httpStatus: number;
    errorResponse: ErrorDataResponse;
  }> {
    const { errorId } = errorDataInput;
    const data = JSON.stringify(errorDataInput);
    const url = this.API_SERVER + "/api/errors/" + `${errorId}`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.put<ErrorDataResponse>(url, data, config);
    return {
      httpStatus: response.status,
      errorResponse: response.data
    };
  }
}

export const errorApi = new ErrorApi();
